import { AccountAppType } from '../common/enums/account-app-type';

interface SignUpStepsLayoutData {
  accountAppType: AccountAppType;
  showMenu: boolean;
}

const useSignUpStepsLayout = () => {
  const signUpStepsLayoutData = useState<SignUpStepsLayoutData>('signUpStepsLayout', () => {
    return {
      showMenu: true,
      accountAppType: AccountAppType.AgentSnap,
    };
  });

  const setAccountAppType = (value: AccountAppType) => {
    signUpStepsLayoutData.value.accountAppType = value;
  };

  const showMenu = (value: boolean) => {
    signUpStepsLayoutData.value.showMenu = value;
  };

  return {
    signUpStepsLayoutData,
    setAccountAppType,
    showMenu,
  };
};

export default useSignUpStepsLayout;
